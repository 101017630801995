import Chart from "chart.js/auto";
import * as React from "react";
import * as speedDate from "speed-date";
import { CostCentre, Month } from "../interfaces";

const monthFormat = speedDate("MMMM YYYY");

function rand<Type>(items: Type[]): Type {
  // "~~" for a closest "int"
  return items[~~(items.length * Math.random())];
}

const colourCombinations = [
  {
    borderColor: "rgb(196 181 253)" /* bg-violet-300 */,
    backgroundColor: "rgb(221 214 254)" /* bg-violet-200 */,
  },
  {
    borderColor: "rgb(249 168 212)" /* bg-pink-300 */,
    backgroundColor: "rgb(251 207 232)" /* bg-pink-200 */,
  },
  {
    borderColor: "rgb(103 232 249)" /* bg-cyan-300 */,
    backgroundColor: "rgb(165 243 252)" /* bg-cyan-200 */,
  },
  {
    borderColor: "rgb(110 231 183)" /* bg-emerald-300 */,
    backgroundColor: "rgb(167 243 208)" /* bg-emerald-200 */,
  },
  {
    borderColor: "rgb(253 224 71)" /* bg-yellow-300 */,
    backgroundColor: "rgb(254 240 138)" /* bg-yellow-200 */,
  },
];

function drawTwoColours() {
  const one = rand(colourCombinations);
  let two = rand(colourCombinations);

  while (one == two) {
    two = rand(colourCombinations);
  }

  return { one, two };
}

interface ChartProps {
  readonly costCentre: CostCentre;
  readonly months: Month[];
}
export const LineChart = ({ costCentre, months }: ChartProps) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const randomColors = drawTwoColours();
    const chart = new Chart(canvasRef.current!, {
      type: "line",
      data: {
        labels: months.map((month) => monthFormat(new Date(month.month))),
        datasets: [
          {
            label: "Total",
            data: months.map(
              (month) => month.costs[costCentre.code]?.total ?? 0,
            ),
            yAxisID: "y",
            borderColor: "rgb(214 211 209)" /* bg-stone-300 */,
            backgroundColor: "rgb(231 229 228)" /* bg-stone-200 */,
            hidden: true,
          },
          {
            label: "Usage",
            data: months.map(
              (month) => month.costs[costCentre.code]?.usage ?? 0,
            ),
            yAxisID: "y",
            ...randomColors.one,
          },
          {
            label: "Marketplace",
            data: months.map(
              (month) => month.costs[costCentre.code]?.marketplace ?? 0,
            ),
            yAxisID: "y",
            ...randomColors.two,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return function cleanup() {
      chart.destroy();
    };
  }, [canvasRef, months]);

  return (
    <div className="w-full h-52 mb-6">
      <canvas className="w-full h-70" ref={canvasRef} />
    </div>
  );
};
